<template>
    <div style="background: #f7f8fa;min-height: 100vh;padding-top: 15px; box-sizing: border-box;">
        <van-cell-group inset>
            <van-cell title="申请人：" value-class="vlo" :value="info.name" />
            <van-cell title="申请项目：" value-class="vlo" :value="info.entry" />
            <van-cell title="具体项目：" value-class="vlo" v-if="info.entry_type" :value="info.entry_type" />
            <van-cell title="申请时间：" value-class="vlo" :value="info.created_time" />
            <van-cell title="审批意见：" value-class="vlo" :value="info.state == 2 ? '未通过' : info.state == 1 ? '通过' : '审核中'" />
            <van-cell title="" value-class="vlo" v-if="info.state == 2">
                <template #label>
                    <div style="color:red; font-size: 14px;line-height: 26px;padding-bottom: 10px;">
                        <span >{{ info.reason }}</span>
                    </div>
                </template>
            </van-cell>
            <van-cell title="生成证书：" v-if="info.state == 1">
                <template #label>
                    <van-image width="100%" height="480px" :src="info.adds" @click="look()" style="margin-top: 10px;" />
                    <div style="text-align: center; padding: 10px 0 5px;"><span>点击证书后长按即可下载</span></div>
                </template>
            </van-cell>


            <van-button type="info" block size="normal" @click="go">返回主页</van-button>
        </van-cell-group>

        <div style="text-align: center; margin: 20px auto;"><van-image round width="40px" height="40px" :src="src" /></div>
    </div>
</template>

<script>
import { Dialog, ImagePreview, Toast } from 'vant';
export default {
    data() {
        return {
            src: require("@/assets/logo.jpg"),
            images: [],
            info: {}
        }
    },
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [ImagePreview.Component.name]: ImagePreview.Component,
        [Toast.name]: Toast, // 文字提示
    },
    mounted() {
        let { id } = this.$route.query;
        if (!id) {
            this.no();
        } else {
            this.post()
        }
    },
    methods: {
        look() {
            ImagePreview({
                images: this.images,
                closeable: true,
            });
        },
        no() {
            Dialog.confirm({
                title: ``,
                message: `暂无申请记录`,
                confirmButtonText: '返回信息页',
                theme: 'round-button',
                showCancelButton: false,
            }).then(() => {
                this.go();
            });

        },
        go() {
            this.$router.replace({ path: '/examine' });
        },
        post() {
            this.loading = true;
            let { id } = this.$localStorage.get('userInfo');
            this.$request(7, { id: this.$route.query.id, uid: id })
                .then((res) => {
                    this.info = res;
                    this.images.push(res.adds)
                    this.loading = false;
                })
                .catch((message) => {
                    Toast(message);
                    this.loading = false;
                })
        }
    }
}

</script>
<style scoped lang='scss'>
.vlo {
    color: #000;
}
</style>